import React from 'react';
import { InlineWidget } from 'react-calendly';

import Testimonials from "../components/Elements/Testimonials"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Demo =  (props) => {
  return (
    <Layout>
      <SEO
        context={{
          ...props.pageContext,
          meta_title: 'Carriyo | Book A Demo',
        }}
        cpath={props.location.pathname}
      />
      <div className="background1 md:py-40 lg:pt-48 pb-20 pt-32 text-center">
        <div className="max-w-6xl mx-auto px-4">
          <h1 className="md:max-w-xl mx-auto sm:text-5xl text-3xl font-medium text-blue mb-2 tracking-tighter">
            How does Carriyo work? We're excited to show you
          </h1>
          <InlineWidget
            pageSettings={{
              backgroundColor: '#FFFFFF00',
            }}
            styles={{ height: '800px' }}
            url="https://calendly.com/sales-team-155/introduction-to-carriyo"
          />
        </div>
      </div>
      <div className="w-full relative">
        <Testimonials />
      </div>
    </Layout>
  )
};

export default Demo;
